import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, TextField, FormControl, Chip, Autocomplete, ListItemText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

function EditTaskSessionModal(props) {
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [notes, setNotes] = useState('');
  const [sessionNotes, setSessionNotes] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    selectedEmployee: false,
    startTime: false,
    endTime: false
  });
  const [customersOpen, setCustomersOpen] = useState(false);

  const isAdmin = props.isAdmin; // Using passed prop to check if the user is an admin

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    let sday = startDate ? startDate : props.schedule.date;
    let st = startTime ? startTime : props.schedule.start_time;
    let et = endTime ? endTime : props.schedule.end_time;

    let schedule = { 
      date: sday,
      start_time: moment(st).date(moment(sday).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      end_time: moment(et).date(moment(sday).date()).utc().format('YYYY-MM-DD HH:mm:00'),
      notes: sessionNotes,
      resource_id: props.schedule.resource_id,
      max_capacity: props.schedule.max_capacity,
      schedule_name: props.schedule.schedule_name,
      task_type: props.schedule.task_type,
    };
    console.log(schedule, startTime, endTime);

    console.log(selectedEmployee.length);

      if(selectedEmployee) {
        try{
          let res = await axios.post(APIURL + '/master_schedule/employee/' + selectedEmployee.id, { schedule_id: props.schedule.schedule_id });
          await createTask();
          props.onSuccess("Employees/Customers assigned to session successfully");
        } catch (error) {
          console.log(error);
          
          props.onError("An error occurred while assigning employees/customers to session");
          return;
        }
      }

    if(startDate !== props.schedule.date || startTime !== props.schedule.start_time || endTime !== props.schedule.end_time) {
      try {
        await axios.put(APIURL + '/master_schedule/' + props.schedule.schedule_id, schedule);
        props.onSuccess("Schedule updated successfully");
      } catch (error) {
        if(error.response) {
          props.onError(error.response.data.message);
        } else {
          props.onError("An error occurred while updating schedule");
        }
        return;
      }
    }

    props.reloadSessionData();
    props.reloadScheduleData();
    props.onClose();
  };

  const handleDeleteEmployee = async (employee) => {
    try {

      await axios.delete(APIURL + '/master_schedule/employee/' + employee.id, { data: { schedule_id: props.schedule.schedule_id } });
      props.reloadScheduleData();
      props.onSuccess("Employee removed from session successfully");
    } catch (error) {
      console.log(error);
      props.onError("An error occurred while removing employee from session");
    }   
  };

  const validate = () => {
    let errors;
    if(isAdmin) {
      errors = {
        startTime: !startTime || moment(startTime).isSameOrAfter(moment(endTime)),
        endTime: !endTime || moment(endTime).isSameOrBefore(moment(startTime))
      };
    } else {
      errors = {
        startTime: !startTime || moment(startTime).isSameOrAfter(moment(endTime)),
        endTime: !endTime || moment(endTime).isSameOrBefore(moment(startTime))
      };
    }
    console.log(errors);
    setErrors(errors);
    setSubmitted(true);
    return !Object.values(errors).some(err => err);
  };

  const unassginedEmployees = () => {
    return props.schedule?.employee_ids ? props.employees.filter(employee => !props.schedule.employee_ids.includes(employee.id)) : props.employees;
  }

  const assginedEmployees = () => {
    return props.schedule?.employee_ids ? props.employees.filter(employee => props.schedule.employee_ids.includes(employee.id)) : [];
  }

  useEffect(() => {
    if (!props.open) {
      setSubmitted(false);
      setErrors({
        selectedEmployee: false,
      });
      setSelectedEmployee([]);
      setNotes('');
    } else { 
      setStartDate(props.schedule?.date ? moment(props.schedule?.date.substring(0, 10)) : '');
      setStartTime(props.schedule?.start_time);
      setEndTime(props.schedule?.end_time);
      setSessionNotes(props.schedule?.notes);
      console.log(props);
    }
  }, [props.open]);

  const createTask = async () => {
    try {
        const customer = props.sessions.filter(session => session.schedule_id === props.schedule.schedule_id)[0];
        const projData = await axios.get(APIURL + '/project', { params: { cus_id: customer.cus_id } });            
        const proj = projData.data.filter(project => project.pro_is_active === true)[0] || projData.data[0];
        const locs = await axios.get(APIURL + '/projlocations', { params: { project_id: proj.pro_id } });
        
        let data = {
            company_id: props.comp_id,
            tTitle: props.schedule.schedule_name,
            tDesc: 'Session Task',
            tProject: proj.pro_id,
            tPreReq: 'NULL',
            tType: props.schedule.task_type,
            tStrtDate: moment(startTime ).format('YYYY-MM-DD HH:mm:ss'),
            tEndDate: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
            tStartTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
            tEndTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
            tLoc: locs.data[0].loc_id,
            tActive: 1,
            tAssTo: props.user.emp_id,
            tPriority: 'Low',
            tIsBillable: props.user.comp_settings.find(setting => setting.feature_id === 56) ? props.user.comp_settings.find(setting => setting.feature_id === 56).feature_value === 'true' : false,
            tNotes: notes || '',
            user_id: props.user.emp_id,
        };
        console.log(data);
        let tasRes = await axios.post(APIURL + '/task', data);
       
        console.log(tasRes);
    } catch (error) {
        console.error(error);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="assign-entity-modal-title"
      aria-describedby="assign-entity-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, maxWidth: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
        <Typography id="assign-entity-modal-title" variant="h6" component="div" sx={{ mb: 1 }}>
          Edit Session
        </Typography>
        <Typography variant="subtitle1">
          {props.schedule?.schedule_name}
        </Typography>
        <Typography variant="subtitle2" component="div" sx={{ mb: 1 }}>
          {props.resources.find(resource => resource.resource_id === props.schedule?.resource_id)?.resource_name} {moment(props.schedule?.date).utc().format('MM/DD/YYYY')} {moment(props.schedule?.start_time).format('hh:mm A')} - {moment(props.schedule?.end_time).format('hh:mm A')}
        </Typography>
        <Typography variant="subtitle2" component="div" sx={{ mb: 1 }}>
            Assigned Employee:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {assginedEmployees().map(employee => (
            <Chip key={employee.id} label={employee.employee_name} color="primary" sx={{ mb: .15}} onDelete={() => handleDeleteEmployee(employee)} />
            ))}
        </Box>
        <FormControl fullWidth margin="normal" sx={{ mt: 1, mb: 2 }}>
            <Autocomplete
            id="employee-select"
            options={isAdmin ? unassginedEmployees() : unassginedEmployees().filter(employee => employee.id === props.user.emp_id)}
            getOptionLabel={(option) => option.employee_name}
            filterSelectedOptions
            disabled={assginedEmployees().length > 0}
            renderInput={(params) => (
                <TextField
                {...params}
                variant="outlined"
                label="Select Employee"
                placeholder="Employee"
                error={errors.selectedEmployee && submitted}
                />
            )}
            onChange={(event, newValue) => {
                setSelectedEmployee(newValue);
                console.log(newValue);
            }}
            renderOption={(props, option) => {
                return (
                <li {...props} key={option.id}>
                    <ListItemText primary={option.employee_name} />
                </li>
                )
            }}
            />
        </FormControl>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box sx={{ display: 'flex ', flexDirection: 'row', gap: 1, mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={startDate}
                renderInput={(params) => <TextField {...params} sx={{ width: '36%' }} />}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <TimePicker
                label="Start Time"
                value={startTime}
                renderInput={(params) => <TextField {...params} sx={{ width: '32%' }} error={errors.startTime && submitted} />}
                onChange={(newValue) => setStartTime(newValue)}
              />
              <TimePicker
                label="End Time"
                value={endTime}
                renderInput={(params) => <TextField {...params} sx={{ width: '32%' }} error={errors.endTime && submitted} />}
                onChange={(newValue) => setEndTime(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <TextField
            margin="normal"
            fullWidth
            label="Session Notes"
            multiline
            rows={4}
            value={sessionNotes}
            onChange={(e) => setSessionNotes(e.target.value)}
            sx={{ mb: 2 }}
          />
{/*           <TextField
            margin="normal"
            fullWidth
            label="Customer Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            sx={{ mb: 2 }}
          /> */}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }}>
            Edit Session
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EditTaskSessionModal;
