import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import QuarterlyAnnualTool from '../MonthlyMonitoring/QuarterlyAnnualTool/QuarterlyAnnualTool';
import MonthlyTool from '../MonthlyMonitoring/MonthlyTool/MonthlyTool';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { APIURL } from '../../../config';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import FileName from './FileName';


const ViewMTModal = (props) => {
    const [showFileName, setShowFileName] = useState(false);
    const [fileName, setFileName] = useState('');
    const ref = useRef();
    const templateString = props.user.comp_settings.find(setting => setting.feature_id === 53)?.feature_value;
    const identifyAnnualVisit = props.user.comp_settings.find(setting => setting.feature_id === 55)?.feature_value;

    useEffect(() => {
        if(props.cus_name && props.checkoutEmpDate && props.type) {
            if(templateString) {
                const values = {
                    customer_name: props.cus_name.replace(' ', '_'),
                    month: moment(props.checkoutEmpDate).utc().format('MMM'),
                    year: moment(props.checkoutEmpDate).utc().format('YYYY'),
                    type: props?.type
                };
                const replacedString = templateString.replace(/{(.*?)}/g, (match, key) => {
                    return values[key] || match; // Use the value from the 'values' object or keep the placeholder if not found
                });
                setFileName(replacedString);
            } else {
                setFileName(props.cus_name.replace(' ', '_') + '_' + moment(props.checkoutEmpDate).utc().format('MMM_YYYY') + `_${props?.type}_Form.docx`,);
            }
        }
    }, [props.cus_name, props.checkoutEmpDate, props.type])

    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    const createReport = window.createReport;
    const mtNA = props.user.comp_settings.find(setting => setting.feature_id === 52 && setting.feature_value === 'true') ? true : false

        const saveFile = async () => {
        function isChromiumBasedBrowser() {
            const userAgent = navigator.userAgent;
            return /Chrome|Chromium|Edg|Brave/i.test(userAgent);
        }
    
        async function fetchTemplate() {
            const response = await axios.get(`${APIURL}/monthlymonitoring/template/${props?.type.toLowerCase()}`, { responseType: 'arraybuffer' });
            return response.data;
        }
    
        async function generateReport(template) {
            return await createReport({
                template,
                data: props.data,
                cmdDelimiter: ['{', '}'],
                fixSmartQuotes: true,
                noSandbox: true
            });
        }
    
        async function saveFileToSystem(blob) {
            const fileHandle = await window.showSaveFilePicker({
                id: "monthly-monitoring",
                suggestedName: `${fileName}.docx`,
                types: [
                    {
                        description: 'Word Document',
                        accept: {
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                        }
                    }
                ]
            });
    
            const writable = await fileHandle.createWritable();
            await writable.write(blob);
            await writable.close();
        }
    
        async function downloadFile(blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
        }
    
        try {
            const template = await fetchTemplate();
            const report = await generateReport(template);
            const blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    
            if (isChromiumBasedBrowser()) {
                await saveFileToSystem(blob);
            } else {
                downloadFile(blob);
            }
    
            await axios.put(`${APIURL}/monthlymonitoring/downloaded/${props.mm_id}`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box>
            <Modal show={props.show} onHide={props.handleClose} dialogClassName="mm-modal-width">
                <Modal.Body>
                    <Modal.Header closeButton={false}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>{ props?.type === "Monthly" ? "Monthly" : "Quarterly/Annual"} Monitoring Tool</Typography>                    
                    </Modal.Header>
                    <Box>
                { props?.type === "Monthly" ?
                    <Box>
                        <MonthlyTool // fully expanded for printing and downloading
                            data={props?.data ? props.data: {}}
                            expand={true}
                            read={true}
                            ref={ref}
                            onChange={() => {return}}
                            mtNA={mtNA}
                        />
                        {props?.type === "Monthly" &&
                            <Box sx={{ overflow: 'hidden', height: 0 }}>
                                <MonthlyTool
                                    data={props?.data ? props.data: {}}
                                    onChange={() => {return}}
                                    expand={true}
                                    note={props.note}
                                    setNote={props.setNote}
                                    review={props.review}
                                    mtNA={mtNA}
                                /> 
                            </Box>
                        }
                        </Box> :
                        <Box>
                            <QuarterlyAnnualTool // fully expanded for printing and downloading
                                data={props?.data ? props.data: {}}
                                expand={true}
                                read={true}
                                ref={ref}
                                onChange={() => {return}}
                                mtNA={mtNA}
                                identifyAnnualVisit={identifyAnnualVisit}
                            />
                            {props?.type === "Quarterly" &&
                                <Box sx={{ overflow: 'hidden', height: 0 }}>
                                    <QuarterlyAnnualTool
                                        data={props?.data ? props.data: {}}
                                        onChange={() => {return}}
                                        ref={ref}
                                        expand={true}
                                        note={props.note}
                                        setNote={props.setNote}
                                        review={props.review}
                                        mtNA={mtNA}
                                        identifyAnnualVisit={identifyAnnualVisit}
                                    /> 
                                </Box>
                            } 
                        </Box>
                        }
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.handleClose}>Close</Button>
                    <Button variant="secondary" onClick={() => saveFile()}>
                        Download
                    </Button>
                    <Button variant="secondary" onClick={handlePrint}>
                        Print
                    </Button>
                </Modal.Footer>
            </Modal>
        </Box>
        
    )
}

export default ViewMTModal;