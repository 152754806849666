import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, Grid, ListItemText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

const ScheduleTaskSessionModal = (props) => {
    const [scheduleName, setScheduleName] = useState('');
    const [customer, setCustomer] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [taskType, setTaskType] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [errors, setErrors] = useState({
        scheduleName: false,
        customer: false,
        selectedDate: false,
        startTime: false,
        endTime: false,
        taskType: false,
    });

    const validate = () => {
        const newErrors = {
            scheduleName: !scheduleName,
            customer: !customer,
            selectedDate: !selectedDate,
            startTime: !startTime || moment(startTime).isSameOrAfter(endTime),
            endTime: !endTime || moment(endTime).isSameOrBefore(startTime),
            taskType: !taskType,
        };
        setErrors(newErrors);
        setSubmitted(true);
        return Object.values(newErrors).every(error => !error);
    };

    const handleScheduleNameChange = (event) => {
        setScheduleName(event.target.value);
    };

    const createTask = async () => {
        try {
            const projData = await axios.get(APIURL + '/project', { params: { cus_id: customer.cus_id } });            
            const proj = projData.data.filter(project => project.pro_is_active === true)[0] || projData.data[0];
            const locs = await axios.get(APIURL + '/projlocations', { params: { project_id: proj.pro_id } });
            
            let data = {
                company_id: props.comp_id,
                tTitle: scheduleName,
                tDesc: 'Session Task',
                tProject: proj.pro_id,
                tPreReq: 'NULL',
                tType: taskType,
                tStrtDate: moment(startTime ).format('YYYY-MM-DD HH:mm:ss'),
                tEndDate: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
                tStartTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                tEndTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
                tLoc: locs.data[0].loc_id,
                tActive: 1,
                tAssTo: props.user.emp_id,
                tPriority: 'Low',
                tIsBillable: props.user.comp_settings.find(setting => setting.feature_id === 56) ? props.user.comp_settings.find(setting => setting.feature_id === 56).feature_value === 'true' : false,
                tNotes: notes || '',
                user_id: props.user.emp_id,
            };
            console.log(data);
            let tasRes = await axios.post(APIURL + '/task', data);
           
            console.log(tasRes);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validate()) {
            return;
        }

        let date = moment(selectedDate);
        let schedule = {
            schedule_name: scheduleName,
            resource_id: null,
            employee_ids: employee ? [employee.id] : [],
            date: date.format('YYYY-MM-DD'),
            start_time: moment(startTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
            end_time: moment(endTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
            max_capacity: null,
            task_type: taskType,
            notes: notes ? notes : null,
            company_id: props.comp_id,
            created_by: props.user.emp_id
        };

        try {
            let response = await axios.post(APIURL + '/master_schedule', schedule);

            if(customer && response?.data?.schedule_id) {
                let session = {
                    schedule_id: response?.data?.schedule_id,
                    customers: [customer.cus_id],
                    date: date.format('YYYY-MM-DD'),
                    start_time: moment(startTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
                    end_time: moment(endTime).set({ year: date.year(), month: date.month(), date: date.date() }).utc().format('YYYY-MM-DDTHH:mm:00'),
                    notes: notes
                };

                await axios.post(APIURL + '/sessions', session);
            }
            
            if(employee && response?.data?.schedule_id) {
                await createTask();
            }
            
            props.reloadScheduleData();
            props.reloadSessionData();
            props.onClose();
            props.onSuccess("Session scheduled successfully");
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    props.onError(error.response.data.message);
                } else {
                    props.onError("An error occurred while scheduling session");
                }
            } else {
                props.onError("An error occurred while scheduling session");
            }
        }
    };

    useEffect(() => {
        if (!props.open) {
            setScheduleName('');
            setCustomer();
            setEmployee();
            setSelectedDate(new Date());
            setStartTime(new Date());
            setEndTime(new Date());
            setNotes('');
            setTaskType('');
            setSubmitted(false);
            setErrors({
                selectedDate: false,
                startTime: false,
                endTime: false,
                taskType: false,
            });
        }
    }, [props.open]);

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Schedule New Session
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        label="Session Name"
                        fullWidth
                        margin="normal"
                        value={scheduleName}
                        onChange={handleScheduleNameChange}
                        error={errors.scheduleName && submitted}
                        inputProps={{ maxLength: 50 }}
                    />
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            id="customer-select"
                            options={props.customers}
                            getOptionLabel={(option) => option.cus_name}
                            renderInput={(params) => <TextField {...params} label="Customer" error={errors.customer && submitted} />}
                            onChange={(event, newValue) => setCustomer(newValue)}
                            value={customer}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <ListItemText primary={option.cus_name} />
                                    </li>
                                );
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            id="employee-select"
                            options={props.isAdmin ? props.employees : props.employees.filter(employee => employee.id === props.user.emp_id)}
                            getOptionLabel={(option) => option.employee_name}
                            renderInput={(params) => <TextField {...params} label={"Employee"} />}
                            onChange={(event, newValue) => setEmployee(newValue)}
                            value={employee}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <ListItemText primary={option.employee_name} />
                                    </li>
                                );
                            }}
                        />
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ mb: 2 }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="task-type-select-label">Task Type</InputLabel>
                                        <Select
                                            labelId="task-type-select-label"
                                            value={taskType}
                                            onChange={(e) => setTaskType(e.target.value)}
                                            error={errors.taskType && submitted}
                                        >
                                            {props.taskTypes.map((taskType, index) => (
                                                <MenuItem key={index} value={taskType}>
                                                    {taskType}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <DatePicker
                                        label="Select Date"
                                        value={selectedDate}
                                        onChange={setSelectedDate}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        error={errors.selectedDate && submitted}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <TimePicker
                                    label="Start Time"
                                    value={startTime}
                                    onChange={setStartTime}
                                    renderInput={(params) => <TextField {...params} fullWidth error={errors.startTime && submitted} />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="End Time"
                                    value={endTime}
                                    onChange={setEndTime}
                                    renderInput={(params) => <TextField {...params} error={errors.endTime && submitted} />}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        inputProps={{ maxLength: 255 }}
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Schedule Session
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ScheduleTaskSessionModal;
